<template>
  <div>
    <v-chip
      x-small
      v-if="item"
      class="white--text"
      :color="get_color(item)"
      label
      >{{ item.attendence == "P" ? "P" : item.attendence }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  watch: {
    item(val) {
      console.log("val", val);
    },
  },
  methods: {
    get_color(item) {
      switch (item.attendence) {
        case "P":
          return "#0277BD";
        case "N/A":
          return "pink";
        case "L":
          return "#81f0abb";
        case "H":
          return "purple";
        case "A":
          return "red darken-2";
        case "W/O":
          return "#b0b0b0";
        case "D/A":
          return "brown";
          case "HL":
            return "#004D40";
          case "FL":
            return "#006064";
      }
    },
  },
};
</script>

<style>
</style>